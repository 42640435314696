@font-face {
  font-family: 'productfont';
  src: url('../font/productfont.eot?96815623');
  src: url('../font/productfont.eot?96815623#iefix') format('embedded-opentype'),
       url('../font/productfont.woff2?96815623') format('woff2'),
       url('../font/productfont.woff?96815623') format('woff'),
       url('../font/productfont.ttf?96815623') format('truetype'),
       url('../font/productfont.svg?96815623#productfont') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'productfont';
    src: url('../font/productfont.svg?96815623#productfont') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "productfont";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-language:before { content: '\e800'; } /* '' */
.icon-involved:before { content: '\e801'; } /* '' */
.icon-nearme:before { content: '\e802'; } /* '' */
.icon-login:before { content: '\e803'; } /* '' */
.icon-settings:before { content: '\e804'; } /* '' */
.icon-report:before { content: '\e805'; } /* '' */
.icon-chat:before { content: '\e806'; } /* '' */
.icon-information:before { content: '\e807'; } /* '' */
.icon-tos:before { content: '\e808'; } /* '' */
.icon-tracing:before { content: '\e809'; } /* '' */
.icon-calendar:before { content: '\e80a'; } /* '' */
.icon-sos:before { content: '\e80b'; } /* '' */
.icon-reset:before { content: '\e80c'; } /* '' */
.icon-survey:before { content: '\e80d'; } /* '' */
.icon-user:before { content: '\e80e'; } /* '' */
.icon-rights:before { content: '\e80f'; } /* '' */
.icon-theme:before { content: '\e810'; } /* '' */
.icon-version:before { content: '\e811'; } /* '' */
.icon-i-restaurant:before { content: '\e812'; } /* '' */
.icon-restrooms:before { content: '\e813'; } /* '' */
.icon-smoking:before { content: '\e814'; } /* '' */
.icon-socialservices:before { content: '\e815'; } /* '' */
.icon-stairs:before { content: '\e816'; } /* '' */
.icon-surgery:before { content: '\e817'; } /* '' */
.icon-texttelephone:before { content: '\e818'; } /* '' */
.icon-ultrasound:before { content: '\e819'; } /* '' */
.icon-volumecontrol:before { content: '\e81a'; } /* '' */
.icon-waitingarea:before { content: '\e81b'; } /* '' */
.icon-womenshealth:before { content: '\e81c'; } /* '' */
.icon-accessibility:before { content: '\e81d'; } /* '' */
.icon-administration:before { content: '\e81e'; } /* '' */
.icon-alternativecomplementary:before { content: '\e81f'; } /* '' */
.icon-ambulance:before { content: '\e820'; } /* '' */
.icon-anesthesia:before { content: '\e821'; } /* '' */
.icon-billing:before { content: '\e822'; } /* '' */
.icon-cardiology:before { content: '\e823'; } /* '' */
.icon-carestaffarea:before { content: '\e824'; } /* '' */
.icon-cathlab:before { content: '\e825'; } /* '' */
.icon-chapel:before { content: '\e826'; } /* '' */
.icon-coffeeshop:before { content: '\e827'; } /* '' */
.icon-dental:before { content: '\e828'; } /* '' */
.icon-dermatology:before { content: '\e829'; } /* '' */
.icon-diabeteseducation:before { content: '\e82a'; } /* '' */
.icon-drinkingfountain:before { content: '\e82b'; } /* '' */
.icon-earnosethroat:before { content: '\e82c'; } /* '' */
.icon-elevators:before { content: '\e82d'; } /* '' */
.icon-emergency:before { content: '\e82e'; } /* '' */
.icon-familypractice:before { content: '\e82f'; } /* '' */
.icon-fireextinguisher:before { content: '\e830'; } /* '' */
.icon-firstaid:before { content: '\e831'; } /* '' */
.icon-genetics:before { content: '\e832'; } /* '' */
.icon-giftshop:before { content: '\e833'; } /* '' */
.icon-healtheducation:before { content: '\e834'; } /* '' */
.icon-healthservices:before { content: '\e835'; } /* '' */
.icon-hearingassistance:before { content: '\e836'; } /* '' */
.icon-hospital:before { content: '\e837'; } /* '' */
.icon-imagingct:before { content: '\e838'; } /* '' */
.icon-i-imagingmri:before { content: '\e839'; } /* '' */
.icon-imagingmritwo:before { content: '\e83a'; } /* '' */
.icon-imagingpet:before { content: '\e83b'; } /* '' */
.icon-imagingrootcategory:before { content: '\e83c'; } /* '' */
.icon-immunizations:before { content: '\e83d'; } /* '' */
.icon-infectiousdiseases:before { content: '\e83e'; } /* '' */
.icon-information-us:before { content: '\e83f'; } /* '' */
.icon-inpatient:before { content: '\e840'; } /* '' */
.icon-intensivecare:before { content: '\e841'; } /* '' */
.icon-internalmedicine:before { content: '\e842'; } /* '' */
.icon-interpreterservices:before { content: '\e843'; } /* '' */
.icon-kidney:before { content: '\e844'; } /* '' */
.icon-laboratory:before { content: '\e845'; } /* '' */
.icon-labordelivery:before { content: '\e846'; } /* '' */
.icon-mammography:before { content: '\e847'; } /* '' */
.icon-medicallibrary:before { content: '\e848'; } /* '' */
.icon-medicalrecords:before { content: '\e849'; } /* '' */
.icon-mentalhealth:before { content: '\e84a'; } /* '' */
.icon-mripet:before { content: '\e84b'; } /* '' */
.icon-neurology:before { content: '\e84c'; } /* '' */
.icon-nosmoking:before { content: '\e84d'; } /* '' */
.icon-nursery:before { content: '\e84e'; } /* '' */
.icon-nutrition:before { content: '\e84f'; } /* '' */
.icon-oncology:before { content: '\e850'; } /* '' */
.icon-ophthalmology:before { content: '\e851'; } /* '' */
.icon-outpatient:before { content: '\e852'; } /* '' */
.icon-pediatrics:before { content: '\e853'; } /* '' */
.icon-pathology:before { content: '\e854'; } /* '' */
.icon-physicaltherapy:before { content: '\e855'; } /* '' */
.icon-registration:before { content: '\e856'; } /* '' */
.icon-radiology:before { content: '\e857'; } /* '' */
.icon-respiratory:before { content: '\e858'; } /* '' */
.icon-pharmacy:before { content: '\e859'; } /* '' */