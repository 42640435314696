@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

body{
	font-family: IBM Plex Sans,sans-serif;
	/* background-color: #3e3c3b; */
	background-color:#3F4E63;
}

.sidebar{
	/* background: #514d4c; */
	background: #2B3645;
}

.login-card{
	background-color: #3d4d61;
	border: 5px solid #e7e8ea;
}

.login-card .btn-primary{
	background: #2d3d4f;
}

.login-card h1.coloryellow{
	color: #fff;
}

.login-card h1.coloryellow:after {
    content: "";
    height: 4px;
    width: 20px;
    display: block;
    background: #fff;
}

.login-card .btn-primary.customcss:hover {
    background-color: #03a9fb;
    border-color: #03a9fb;
}

.login-card .btn-link.customcss:hover {
    color: #03a9fb;
}

.login-card input.form-control{border-bottom-width: 0px;border-radius: 0.25rem !important;}

.login-card input.form-control:focus{
	box-shadow: none;
	border-color: transparent;
}

.login-card .input-group-text{
	background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0px;
}

.login-card .input-group-text i{
	color: #fff;
	font-size: 16px;
	font-size: 24px;
}

.sidebar .nav-link.active {
    color: #fff;
    background: #2f2c2c;
    color: #03a9fb;
}

.sidebar .nav-link.active:hover{
    color: #fff;
    background: #2f2c2c;
}

.sidebar .nav-link:hover {
    color: #fff;
    background: #4382ac;
}

.sidebar .nav-link .nav-icon {
    display: inline-block;
    width: 1.09375rem;
    margin: 0 .5rem 0 0;
    font-size: .875rem;
    color: #fff;
    text-align: center;
}

.tab-content {
    margin-top: -1px;
    /* background: #3e3c3b; */
    background: #2B3645;
    border: 1px solid #687e9a;
}

.nav-tabs .nav-link.active {
    color: #fff;
    background: #3d4d61;
    border-color: #c8ced3;
    border-bottom-color: #fff;
}

.nav-tabs .nav-link {
    color: #fff;
    background: #4382ac;
    border-radius: 0px !important;
}

.card-header {
    background: #4382ac;
    color: #fff;
}

.card{
	background-color: transparent;
	color: #fff;
	border: 1px solid #687e9a;
}

.highcharts-background{
	background-color: transparent;
	fill: transparent;
}

.highcharts-axis-title, .highcharts-axis-labels,.highcharts-axis-labels text,.highcharts-legend-item text,
.highcharts-data-label{
	color: #fff;
	fill: #fff;
	font-family: IBM Plex Sans,sans-serif !important;
}

.surveydiv .bg-info,.servicesdiv .bg-info{
	background-color: #2d3d4f !important;
	border:none;
	border-left: 5px solid #03a9fb !important;
	border-radius: 0px;
}

.surveydiv .bg-info .card-title, .servicesdiv .bg-info .card-title{
	background: #2f2c2c;
	padding:10px;
	color: #03a9fb;
	font-weight: normal;
	margin-bottom: 0px;
}

.surveydiv .bg-info i, .servicesdiv .bg-info i{
	padding: 10px;
    margin-top: 5px !important;
    display: block;
}

.surveydiv .bg-info .card-body, .servicesdiv .bg-info .card-body{
	padding:0px !important;
	margin-bottom: 0px !important;
}

.surveydiv .bg-info .text-value , .servicesdiv .bg-info .text-value{
	font-family: Rubik,sans-serif;
    font-weight: 400!important;
   padding: 5px 10px;
    font-size: 1.6rem;
}



.surveydiv .bg-primary,.servicesdiv .bg-primary{
	background-color: #2d3d4f !important;
	border:none;
	border-left: 5px solid #0bf5e0 !important;
	border-radius: 0px;
}

.surveydiv .bg-primary .card-title, .servicesdiv .bg-primary .card-title{
	background: #2f2c2c;
	padding:10px;
	color: #0bf5e0;
	font-weight: normal;
	margin-bottom: 0px;
}

.surveydiv .bg-primary i, .servicesdiv .bg-primary i{
	padding: 10px;
    margin-top: 5px !important;
    display: block;
}

.surveydiv .bg-primary .card-body, .servicesdiv .bg-primary .card-body{
	padding:0px !important;
	margin-bottom: 0px !important;
}

.surveydiv .bg-primary .text-value , .servicesdiv .bg-primary .text-value{
	font-family: Rubik,sans-serif;
    font-weight: 400!important;
   padding: 5px 10px;
    font-size: 1.6rem;
}


.surveydiv .bg-warning,.servicesdiv .bg-warning{
	background-color: #2d3d4f !important;
	border:none;
	border-left: 5px solid #f5d63d !important;
	border-radius: 0px;
}

.surveydiv .bg-warning .card-title, .servicesdiv .bg-warning .card-title{
	background: #2f2c2c;
	padding:10px;
	color: #f5d63d;
	font-weight: normal;
	margin-bottom: 0px;
}

.surveydiv .bg-warning i, .servicesdiv .bg-warning i{
	padding: 10px;
    margin-top: 5px !important;
    display: block;
}

.surveydiv .bg-warning .card-body, .servicesdiv .bg-warning .card-body{
	padding:0px !important;
	margin-bottom: 0px !important;
}

.surveydiv .bg-warning .text-value , .servicesdiv .bg-warning .text-value{
	font-family: Rubik,sans-serif;
    font-weight: 400!important;
   padding: 5px 10px;
    font-size: 1.6rem;
}


.surveydiv .bg-danger,.servicesdiv .bg-danger{
	background-color: #2d3d4f !important;
	border:none;
	border-left: 5px solid #ff3f48 !important;
	border-radius: 0px;
}

.surveydiv .bg-danger .card-title, .servicesdiv .bg-danger .card-title{
	background: #2f2c2c;
	padding:10px;
	color: #ff3f48;
	font-weight: normal;
	margin-bottom: 0px;
}

.surveydiv .bg-danger i, .servicesdiv .bg-danger i{
	padding: 10px;
    margin-top: 5px !important;
    display: block;
}

.surveydiv .bg-danger .card-body, .servicesdiv .bg-danger .card-body{
	padding:0px !important;
	margin-bottom: 0px !important;
}

.surveydiv .bg-danger .text-value , .servicesdiv .bg-danger .text-value{
	font-family: Rubik,sans-serif;
    font-weight: 400!important;
   padding: 5px 10px;
    font-size: 1.6rem;
}


.surveydiv .bg-success,.servicesdiv .bg-success{
	background-color: #2d3d4f !important;
	border:none;
	border-left: 5px solid #79c267 !important;
	border-radius: 0px;
}

.surveydiv .bg-success .card-title, .servicesdiv .bg-success .card-title{
	background: #2f2c2c;
	padding:10px;
	color: #79c267;
	font-weight: normal;
	margin-bottom: 0px;
}

.surveydiv .bg-success i, .servicesdiv .bg-success i{
	padding: 10px;
    margin-top: 5px !important;
    display: block;
}

.surveydiv .bg-success .card-body, .servicesdiv .bg-success .card-body{
	padding:0px !important;
	margin-bottom: 0px !important;
}

.surveydiv .bg-success .text-value , .servicesdiv .bg-success .text-value{
	font-family: Rubik,sans-serif;
    font-weight: 400!important;
   padding: 5px 10px;
    font-size: 1.6rem;
}

.btn-outline-dark{
	background: #2d3d4f;
    color: #fff;
    border:1px solid #fff;
}

.btn-outline-dark:hover{
    border:1px solid #fff;
}

.btn-primary{
	background-color: #1d6996;
	border-color: #1d6996;
}

.form-check-input:disabled~.form-check-label {
    color: #c1c1c1;
}

.text-muted {
    color: #1b2f42!important;
}

.table thead th {
    font-weight: 700;
    font-family: IBM Plex Sans,sans-serif;
    background: #2d3d4f;
    color: #fff;
}

.table thead th:focus{
	outline: none;
}

tr{
    color: #fff!important;
    background-color: #4382ac;
}

tr:nth-child(2n) {
    background: #3d4d61!important;
    color: #fff;
}

.table_react_bootstrap .react-bs-table-csv-btn {
    border: 1px solid #e4e7ea;
    background: #2d3d4f;
    color: #fff;
}

.react-bootstrap-table-pagination-total{color: #fff;}

#pageDropDown{
	color: #fff;
    background-color: #4382ac;
    border-color: #4382ac;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #2d3d4f;
    border-color: #20a8d8;
}

.page-link:focus{
	box-shadow: none;
}

.table_react_bootstrap{
	color: #fff;
}

/*  Tab component */
.nav-tabs .ssup.active{
    background: #20a8d8 !important;
    color: #fff;
}

.sstab > li.active a, .sstab > li.active a:hover {
    background-color: #20a8d8;
    color: #fff;
}

.sstab > li:first-child {
    border-left: 0;
}
.sstab > li {
    position: relative;
    overflow: visible;
    /* border-right: 12px solid #fff;
    border-left: 12px solid #fff; */
    border-right: 12px solid #3f4e63;
    border-left: 12px solid #3f4e63;
}

.sstab > li.active a {
    background-color: #20a8d8;
    border-radius: 0;
}
.sstab > li:first-child a {
    border-radius: 5px 0 0 5px;
}



.sstab > li .nav-arrow {
    position: absolute;
    top: 0px;
    right: -20px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 20px 0 17px 20px;
    border-color: transparent transparent transparent #eee;
    z-index: 150;
}

.sstab > li.active .nav-arrow {
    border-color: transparent transparent transparent #20a8d8;
}

.sstab > li.active .nav-wedge {
    border-color: #20a8d8 #20a8d8 #20a8d8 transparent;
}

.sstab > li .nav-wedge {
    position: absolute;
    top: 0px;
    left: -20px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 20px 0 17px 20px;
    border-color: #eee #eee #eee transparent;
    z-index: 150;
}

.sstab .nav-link {
    color: #73818f;
    background: #eee;
}

.sstab .nav-item a{
    border:none !important;
}

.sstab>li+li {
    margin-left: 0;
}

.sstab > li.active a {
    background-color: #3d4d61;
    border-radius: 0;
}

.sstab > li.active .nav-arrow {
    border-color: transparent transparent transparent #2b3645;
}

.sstab .nav-link {
    color: #73818f;
    background: #4382ac;
}

.sstab > li .nav-wedge {
    position: absolute;
    top: 0px;
    left: -20px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 20px 0 17px 20px;
    border-color: #4382ac #4382ac #4382ac transparent;
    z-index: 150;
}

.sstab > li .nav-arrow {
    position: absolute;
    top: 0px;
    right: -20px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 20px 0 17px 20px;
    /* border-color: transparent transparent transparent #4382ac; */
    border-color: #3f4e63 #3f4e63 #3f4e63 #4382ac;
    z-index: 150;
}

.sstab > li.active .nav-wedge {
    border-color: #2b3645 #2b3645 #2b3645 transparent;
}

.sstab > li.active a, .sstab > li.active a:hover {
    background-color: #2b3645;
    color: #fff;
}

/*  */

.tab-pane .card-footer{
	background-color: transparent !important;
	border-top:1px solid #687e9a;
}

#en_PH label,#en_US label{color: #fff;}

#addItems .card-body,.modal-body{padding:0px;}

#addItems .nav-tabs{background-color: #4382ac;}

#addItems .nav-tabs .nav-link.active:focus{
    background: #3d4d61;
    border-color: transparent;
    border-bottom-color: #fff;
}

#addItems .nav-tabs .nav-link:focus{
	background: #4382ac;
    border-color: transparent;
    border-bottom-color: #fff;
}

#addItems .nav-tabs .nav-link.active {
    color: #fff;
    background: #3d4d61;
    border-color: transparent;
    border-bottom-color: #fff;
}

.survey-btn{
	background-color: #4382ac !important;
    color: #fff !important;
    border-radius: 0px !important;
}

.modal-body{
	color: #fff;
	background-color: #3e3c3b;
}

.modal-header{
	background-color: #4382ac;
	color: #fff;
}

.modal-header button.close{
	opacity: 1;
	color: #fff;
}

.modal-body .nav-tabs .nav-link.active:focus, #services .nav-tabs .nav-link.active:focus,
#survey .nav-tabs .nav-link.active:focus{
    background: #3d4d61;
    border-color: transparent;
    border-bottom-color: #fff;
}

.modal-body .nav-tabs .nav-link:focus, #services .nav-tabs .nav-link:focus,
#survey .nav-tabs .nav-link:focus{
	background: #4382ac;
    border-color: transparent;
    border-bottom-color: #fff;
}

.modal-body .nav-tabs .nav-link.active, #services .nav-tabs .nav-link.active , #survey .nav-tabs .nav-link.active{
    color: #fff;
    background: #3d4d61;
    border-color: transparent;
    border-bottom-color: #fff;
}

.service-name,.service-question-name{color: #fff;}

#services .list-group-item,#survey .list-group-item{
	background-color: #3e3c3b;
	color: #fff;
	border:none;
}

#services .list-group-item button, #survey .list-group-item button{
	color: #fff;
}

#services .list-group-item.active, #survey .list-group-item.active{
	background-color: #3d4d61;
	color: #fff;
}

.nearmeTab .btn-light.active{
	background-color: #3d4d61 ;
    border-color: #c8ced3;
    border-bottom-color: #fff !important;
    color: #fff ;
    border-bottom: 1px solid #fff !important;
}

.nearmeTab .btn-light{
	background-color: #4382ac ;
    border-color: #c8ced3;
    border-bottom-color: #fff !important;
    color: #fff ;
    border:none !important;
}

.nearmeTab .btn-light:focus{
	box-shadow: none !important;
}

.nearmeTab{
	border:none !important;
}

#mapList li.list-group-item.list-group-item-action{
	background-color: #4382ac;
	color: #fff;
	margin-bottom: 1.5rem;
	border-bottom: 1px solid #c8ced3;
	border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}

.dropzone {
    border: 2px dashed #03a9fb;
    border-radius: 5px;
    background: transparent;
    height: 300px;
    line-height: 300px;
    text-align: center;
    cursor: pointer;
    color: #fff;
}

.dropzone:focus,.dz-message:focus,.dropzone div:focus,.dropzone section:focus{
	outline: none;
}

.dropzone .dz-message {
    color: #fff;
    font-weight: 500;
    font-size: medium;
    font-size: initial;
    text-transform: uppercase;
}

.card-header.bg-transparent {
    background-color: #4382ac;
    color: #fff;
}

.inbox .bg-white{
	background: transparent;
    background-color: transparent;
    color: #fff;
    /* border: 1px solid #687e9a; */
}

.inbox .card .case_item.active {
    background-color: #2f2c2c;
    color: #fff;
}

.form-control-plaintext{
	background-color: #e4e7ea;
    opacity: 1;
    width: 50%;
    padding: 6px 12px;
    border-radius: .25rem;
}

.form-control-plaintext:focus{
	outline:none;
}

.mapviewdefault .css-1pcexqc-container .css-dvua67-valueContainer{
	color: #333;
}

.css-fk865s-option,.css-fk865s-option:hover{
	color: #333 !important;
}

.css-1thkkgx-indicatorContainer:hover{color: #333 !important;
}

.css-fk865s-option:active,.css-fk865s-option:hover{background-color:#2f2c2c !important;}
.css-dpec0i-option:active,.css-dpec0i-option:hover{background-color:#2f2c2c !important;}

.css-fk865s-option:active{background-color:#2f2c2c !important;}
.css-dpec0i-option{background-color:#2f2c2c !important;color:#fff !important;}
.css-dpec0i-option:active{background-color:#2f2c2c !important;}

.addinfobtn{
	background-color: #4382ac;
    color: #fff;
    border-radius: 0px !important;
    border-color: transparent;
}

.addinfobtn:hover{
	background-color: #4382ac;
    color: #fff;
    border-radius: 0px !important;
    border-color: #d1dbe1;
}

.addinfobtn:focus,.addinfobtn:active{
	box-shadow: none;
	background-color: #4382ac !important;
    color: #fff !important;
    border-radius: 0px !important;
    border-color: transparent !important;
}

.servicemodal .control-label {padding-left: 16px;}
.servicemodal .addbtn{margin-left: 16px;}
.servicemodal .control-label:first-child{padding-top: 10px;}

.servicemodal .css-ssi0ig-container{padding-left: 16px;padding-right: 16px;}

.reportlistgroup a{
	background-color: #4382ac !important;
    color: #fff !important;
    border-top: none !important;
    border-bottom: 1px solid #c8ced3 !important;
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    margin-bottom: 1.5rem !important;
}

.translationmodal .modal-body{
	background-color: #3e3c3b;
}

#languageForm{
	padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    background-color: #3e3c3b;
}

.translationmodal .modal-footer{
	margin-left: -16px;
    margin-right: -16px;
    padding: 12px 20px;
    display: block;
}

.translationTable thead th{
	font-weight: 700;
    font-family: IBM Plex Sans,sans-serif;
    background-color: #2d3d4f !important;
    color: #fff !important;
    font-size:12px !important;
}

.table-striped tbody tr:nth-of-type(odd){
	color: #fff;
    background-color: #4382ac;
}

.casedetailsmodal .modal-body{
	padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
}

.casedetailsmodal .modal-footer{
	background-color: #f0f3f5;
    padding: 12px 20px;
    text-align: center;
    display: block;
}

.casedetailsmodal .modal-footer button.btn-primary{
	margin-right: 1rem;
}

.nominalgroup div.input-group{margin-bottom: 10px;}

 .bgLogin {
  background: url(../images/loginpagebg.jpg);
  background-size: cover;
  background: linear-gradient(0deg,rgba(45,61,79,.7),rgba(45,61,79,.7)),url(../images/loginpagebg.jpg);
  background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
  /* height: calc(100vh - 77px);
  min-height: calc(100vh - 77px) !important;
  margin-top: 77px; */
}

.footer{
	 position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #2d3d4f;
    background: #2d3d4f;
    padding: 7px 25px;
    color: #fff;
    font-size: 10px;
    letter-spacing: .3px;
    text-transform: uppercase;
}

.footer p{
	margin-bottom: 0px;
	display: inline-block;
}

.footer img {
    height: 20px;
}

.header{
	position: absolute;
    top: 0;
    width: 100%;
    background-color: #4382ac;
    background: #4382ac;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    padding-top: 9px;
    padding-bottom: 9px;
}

.keypop-col .text-value{
    font-size: 1.2rem !important;
}

.keypop-col .card-text-icon{
    font-size: 1rem !important;
}

.bg-darkblue{
    background:#2B3645;

}

.bg-cloudburst{
    background: #333F50;
}

.h-300{
    height: 300px;
}

.h-600{
    height:600px;
}

.h-400{
    height: 400px;
}

.h-350{
    height: 350px;
}

.h-250{
    height: 250px;
}

.register-prepend{
    width: 55px;
}

.cursor-pointer{
    cursor: pointer;
}


/* Let's get this party started */
::-webkit-scrollbar {
    width: 12px;
}
 
/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(51, 63, 80,0.8); 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(51, 63, 80,0.4); 
}

.inbox_chat{
    background-color: transparent !important;
    border: 1px solid #687e9a;
    /*border-top: none;*/
    /* border-bottom: none; */
    border-radius: 5px;
   /* border-top-left-radius: 0px;*/
   padding-top: 10px;
}

.inbox_chat .response_chat .dateTime{
    color: #fff;
    text-align: right;
}

.response_chat{
    background-color: #2b3645;
    font-weight: 700;
    border-radius: 0 15px 0px 15px;
    max-width: 60%;
    width: fit-content;
    padding: 5px 10px;
    min-width: 40%;
    padding-bottom: 10px !important;
    margin-bottom: 20px !important;
}

.inbox_chat .response_chat .chat_content{
    background-color: #2b3645;
    max-width: 100%;
}

.inbox_chat .request_chat .chat_content {
    background-color: #fff;
    font-weight: 700;
    border-radius: 20px 0 20px 20px;
    max-width: 100%;
    padding: 5px 10px;
    position: relative;
    margin: 0 auto 0 0;
    display: table;
}

.request_chat{
    background-color: #fff;
    font-weight: 700;
    border-radius: 15px 0 15px 0px;
    max-width: 60%;
    padding: 5px 10px;
    position: relative;
    margin: 0 0 0 auto;
    display: table;
    min-width: 40%;
    padding-bottom: 10px !important;
    margin-bottom: 20px !important;
}

.inbox_chat .response_chat .chat_content .text {
    /* word-wrap: break-word;
    word-break: break-all; */
    color: #fff;
}

.chatlistaccordionholder{
    height: calc(100vh - 125px);
    overflow-y: auto;
}

.sendbtnholder{
    background-color: #ddd;
    border-radius: 5px;
    padding: 15px 30px;
}

.sendbtnholder .form-group{
    margin-bottom: 0px;
    width: calc(100% - 10px);
}

.sendbtnholder .form-group:focus-visible,
.sendbtnholder .form-group input:focus-visible{
    outline: none !important;
}

.sendbtnholder .form-group input:focus{
    box-shadow: none !important;
}

.keypoplabel{
   /* background: #2b3645;
    padding: 5px 10px;
    border-radius: 5px;*/
}

.border-white{
    border: 1px solid #fff;
}

.react-bootstrap-table.table-responsive{
    border: 1px solid #fff;

}

.chatlistaccordionholder .accordion .bg-white.card.active .card-header{
    background-color: #2f2c2c !important;
    background-color: #2b3645 !important;
}

.chatlistaccordionholder .accordion .bg-white.card.active{
    border: 1px solid #6b6565;
}

.chatlistaccordionholder .accordion .bg-white.card .badge{
    position: absolute;
    top: -6px;
    left: 32px;
}

.chatlistaccordionholder .accordion .bg-white.card i.fa.fa-comment-dots{
    margin-top: 7px;
}


.chatlistaccordionholder .accordion .bg-white.card .card-header {
  background-position: center;
  transition: background 0.8s;
}
.chatlistaccordionholder .accordion .bg-white.card .card-header:hover {
  background: #4382ac radial-gradient(circle, transparent 1%, #4382ac 1%) center/15000%;
}
.chatlistaccordionholder .accordion .bg-white.card .card-header:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}

.modal .inbox_chat .response_chat .chat_content,
.modal .inbox_chat .request_chat .chat_content{
    background-color: transparent;
}

.modal .inbox_chat .response_chat .dateTime {
    text-align: right;
}

.modal .inbox_chat .request_chat {
    background-color: #4382ac;
    font-weight: 700;
    border-radius: 15px 0 15px 0px;
    max-width: 60%;
    padding: 5px 10px;
    position: relative;
    margin: 0 0 0 auto;
    display: table;
    min-width: 40%;
    padding-bottom: 10px !important;
    margin-bottom: 20px !important;
}

.modal .inbox_chat .request_chat .chat_content {
    
    margin: 0 auto 0 0 ;
    color: #fff;
   
}

.modal .inbox_chat .request_chat .dateTime {
    text-align: right;
    color: #fff;
}

.modal .inbox_chat {
    background-color: #f2f2f2 !important;
    border-radius: 0px !important;
}

.pl-20px{
    padding-left: 20px;
}

.pl-0px{
    padding-left: 0px;
}

.pl-5px {
    padding-left: 5px;
}